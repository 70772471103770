
  <div class="about">
    <img class="cpjs" src="https://qiniu.qsmiaoyu180.cn/website/cpjs%402x.png" alt="">
    <div class="container">
      <!-- frist -->
      <div class="frist">
        <img class="cpyjs_01" src="https://qiniu.qsmiaoyu180.cn/website/cpyjs_01%402x.png" alt="">
        <div class="fristContent">
          <div class="qsmy_box">
            <div class="qsmy_title">清声妙语APP</div>
            <img class="qsmyImg" src="https://qiniu.qsmiaoyu180.cn/website/qsmy_%3Bogo%402x.png" alt="">
          </div>

          <p>清声妙语是一款适用于中小学生语言能力培养，和普通话水平提升的产品。产品将部编《语文》教材，教育部推荐书目，自编教材组成庞大的内容知识库；根据“听读背诵讲”五项能力特性，设计最为合适的题型。 </p>
          <p>清声妙语产品根据中小学生不同年龄段，在内容知识库中挑选合适的内容融合对应到相应的能力点。运用语音合成技术，卡拉 OK
            音视频合成技术，动图融合技术将课程内容制作的尽可能友好、趣味，贴近青少年群体。运用国内最先进的语音识别技术和中小学生普通话水平测试系统，在线提供练习、测评、辅导等教学服务。产品功能包括：</p>

          <div class="feature">
            <div class="feature_item">
              <div class="feature_num">01</div>
              <p class="feature_content">中小学生普通话水平在线模拟测试，测试标准符合教育部颁布的《中小学生普通话水平测试等级标准及测试大纲》要求，是目前唯一授权接入国家语委考试系统的产品。</p>
            </div>
            <div class="feature_item">
              <div class="feature_num">02</div>
              <p class="feature_content">
                语言能力培养练习课程，此课程内容是由专家名师耗时4年共同打造而成，旨在科学有效地培养孩子的语言能力，激发孩子对语文学习的兴趣，并帮助孩子在普通话水平测试中取得好成绩。</p>
            </div>
            <div class="feature_item">
              <div class="feature_num">03</div>
              <p class="feature_content">名师课堂、朗诵、演讲作品视频等产品功能，寓教于乐，帮助孩子们丰富知识、熏染文化、形成气质，培养并实现孩子们精彩的语言表达。</p>
            </div>
          </div>

        </div>
      </div>
      <!-- second -->
      <div class="second">
        <div class="second_left">
          <div class="qsmy_box">
            <div class="total">
              <div class="bag"></div>
              爱读诵讲
            </div>
            <img class="totalImg" src="https://qiniu.qsmiaoyu180.cn/website/adsj_logo%402x.png" alt="">
          </div>

          <p>爱读诵讲是清声妙语APP的TV端（电视端）产品，目前已经在中国移动IPTV高清智能语音机顶盒（全国各省逐步上线中），以及深圳天威视讯高清智能语音机顶盒中上线。</p>
        </div>
        <img class="cpyjs_02" src="https://qiniu.qsmiaoyu180.cn/website/cpyjs_02%402x.png" alt="">
      </div>
      <!-- third -->
      <div class="second">
        <div class="sthird_item">
          <div class="total">
            <div class="bag"></div>
            中小学生普通话水平测试（模拟）
          </div>
          <p>妙语教育为广大中小学生提供了一整套普通话水平的测试产品，这套模拟测试产品是目前唯一被授权对接国家语委/全球中文学习平台中小学生普通话水平考试系统的产品，帮助孩子通过教育部即将开始的
            “中小学生普通话水平测试”，取得好成绩。</p>
        </div>
        <div class="sthird_item">
          <div class="qsmy_box">
            <div class="total">
              <div class="bag"></div>
              慧唱慧说
            </div>
            <img class="totalImg2" src="https://qiniu.qsmiaoyu180.cn/website/hshc_logo%402x.png" alt="">
          </div>
          <p>
            慧唱慧说是一款适用于3~6岁学龄前儿童的产品。采用先进的语音识别、口语评测等技术；课程内容依照教育部颁布的《3-6岁儿童学习与发展指南》，并结合幼儿的学习特点与认知序列，从“认识数字、辨识物象、动口动手、习练礼仪、爱国爱家、培养习惯、细心观察、热爱自然、尝试想象、知晓道理”等十个方面来引导幼儿养成良好的生活、学习习惯，并培养雅致的趣味和健康的心智。
          </p>
        </div>

      </div>
      <!-- four -->
      <div class="total" style="marginTop:89px">
        <div class="bag"></div>
        青少年语言表达能力培养训练课程
      </div>
      <div class="fourList">
        <div class="fourItem">
          <img class="fourImg" src="https://qiniu.qsmiaoyu180.cn/website/cpyjs_03%402x.png" alt="">
          <p> 模拟语文考试场景，使用语音识别技术进行“选择题”、“问答题”、“判断题”的答题练习，每次练习2-3题。
          </p>
          <p> 1）提升听力；</p>
          <p>2）巩固知识；</p>
          <p>3）提高专注力。</p>
        </div>
        <div class="fourItem" style="marginLeft:3%">
          <img class="fourImg" src="https://qiniu.qsmiaoyu180.cn/website/cpyjs_04%402x.png" alt="">
          <p> 模拟教育部《中小学生普通话水平等级测试》场景，使用语音测评技术进行读“字词句段”练习。每次练习1-2题。
          </p>
          <p> 1）提升发音准确性；</p>
          <p> 2）加深内容知识理解；
          </p>
          <p>3）培养良好语感；</p>
          <p>4）评测报告纠正错误。</p>
        </div>
        <div class="fourItem" style="marginLeft:3%">
          <img class="fourImg" src="https://qiniu.qsmiaoyu180.cn/website/cpyjs_05%402x.png" alt="">
          <p>使用语音测评技术+DIY作品合成技术，实现：
          </p>
          <p>1）背书很有趣；</p>
          <p> 2）有人陪我一起背。
          </p>
          <p>从而解决不愿意背，记不住，记错等常见的背诵学习问题。
          </p>


        </div>
        <div class="fourItem">
          <img class="fourImg" src="https://qiniu.qsmiaoyu180.cn/website/cpyjs_06%402x.png" alt="">
          <p>使用语音测评技术+卡拉ok技术+DIY作品合成技术，实现：</p>
          <p> 1）诵读很有趣；</p>
          <p> 2）跟名师学习诵读技巧；</p>
          <p> 3）酷炫的作品很有成就感，个人视频作品更适合大屏展示。</p>


        </div>
        <div class="fourItem" style="marginLeft:3%">
          <img class="fourImg" src="https://qiniu.qsmiaoyu180.cn/website/cpyjs_07%402x.png" alt="">
          <p> 模拟教育部《中小学生普通话水平等级测试》场景，使用语音识别技术+语音测评技术+语音转文字技术进行口语表达练习。每次练习1题。
          </p>
          <p>1）GPT大模型，提供智能批改与建议；</p>
          <p> 2）提升逻辑思维能力；
          </p>
          <p>3）提高作文水平；</p>
          <p> 4）纠正日常口语表达不良习惯。
          </p>


        </div>

      </div>
    </div>

  </div>
